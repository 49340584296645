import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import smoothscroll from 'smoothscroll-polyfill'; // Import the smoothscroll polyfill
import { handleNavigationClick } from './functions.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faSpotify } from '@fortawesome/free-brands-svg-icons';



smoothscroll.polyfill();



const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [shrink, setShrink] = useState(false);
  const location = useLocation();
  const [scrollProgress, setScrollProgress] = useState(0);
  const startColor = 'rgb(249, 247, 220)'; // #F9f7DC
  const endColor = 'rgb(116, 104, 97)'; // #746861

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };



  const interpolateColor = (startColor, endColor, progress) => {
    const start = startColor.match(/\d+/g).map(Number);
    const end = endColor.match(/\d+/g).map(Number);

    const r = Math.round(start[0] + (end[0] - start[0]) * progress);
    const g = Math.round(start[1] + (end[1] - start[1]) * progress);
    const b = Math.round(start[2] + (end[2] - start[2]) * progress);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const currentColor = interpolateColor(startColor, endColor, scrollProgress);


  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.querySelector('.about-section');
      if (aboutSection !== null) {

        const aboutSectionTop = aboutSection.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        // Calculate the scroll position as a value between 0 and 1
        const progress = Math.min(1, Math.max(0, (windowHeight - aboutSectionTop) / windowHeight));

        // Set the progress value to state
        setScrollProgress(progress);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener
    return () => window.removeEventListener('scroll', handleScroll);

  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShrink(true);
      } else {
        setShrink(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navbar-section ${menuOpen ? 'open' : ''}`}
      style={{
        borderRight: `1px solid ${currentColor}`, // Gradual color transition
        transition: 'border-color 0.2s ease-in-out',
      }}
    >
      <div className="navbar-container">
        <header className={`navbar-header ${menuOpen ? 'open' : ''}`}
        >
          <div class="menu-button-wrap">
            <button className={`menu-button ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <span className="bar1" style={{
                backgroundColor: menuOpen ? '#F9f7DC' : scrollProgress > 0.9 ? '#746861' : '#F9f7DC', // Progressive background change
                transition: 'all 0.5s ease-in-out, color 0.3s ease-in-out',
              }}></span>
              <span className="bar2"></span>
              <span className="bar3"
                style={{
                  backgroundColor: menuOpen ? '#F9f7DC' : scrollProgress > 0.9 ? '#746861' : '#F9f7DC', // Progressive background change
                  transition: 'all 0.5s ease-in-out, color 0.3s ease-in-out'
                }}
              ></span>
            </button>
            
            <div class={`menu-options ${menuOpen ? 'open' : ''}`}>

              <span class="menu-button-text"> 
                {/* Colocar hiperligaçãop para pagina MENU */}
                <a  class="menu-nav-option"> 
                  menu
                </a>
              </span>
            </div>
          </div>

          <span className={`navbar-logo-text ${menuOpen ? 'open' : ''}`}>
            <a href="/" class="home-nav-option" 
            style={{
              color: menuOpen ? '#F9f7DC' : scrollProgress > 0.5 ? '#746861' : '#F9f7DC', // Change color based on menuOpen and scrollProgress
              transition: 'color 0.3s ease-in-out',
            }}
            >
            ɫ
            </a>
          </span>
          <div className="navbar-social-icons-container">
            <ul className="navbar-social-icons">
              <li>
                <a
                  href="https://www.instagram.com/lukat.opo/"
                  style={{
                    color: menuOpen ? '#F9f7DC' : scrollProgress > 0.15 ? '#746861' : '#F9f7DC', // Change color based on menuOpen and scrollProgress
                    transition: 'color 0.3s ease-in-out',
                  }}
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a
                  href="http://www.tiktok.com/@lu__kat"
                  style={{
                    color: menuOpen ? '#F9f7DC' : scrollProgress > 0.1 ? '#746861' : '#F9f7DC', // Change color based on menuOpen and scrollProgress
                    transition: 'color 0.3s ease-in-out',
                  }}
                >
                  <FontAwesomeIcon icon={faTiktok} />
                </a>
              </li>
              <li>
                <a
                  href="https://open.spotify.com/playlist/2AoawW3WKF6pL64QT0jEEr?si=NKmgKSS1SICfuo93RC1CWg&pi=e-jJHk_DI5RSiR"
                  style={{
                    color: menuOpen ? '#F9f7DC' : scrollProgress > 0.05 ? '#746861' : '#F9f7DC', // Change color based on menuOpen and scrollProgress
                    transition: 'color 0.3s ease-in-out',
                  }}
                >
                  <FontAwesomeIcon icon={faSpotify} />
                </a>
              </li>
            </ul>
          </div>
        </header>

      </div>
    </div>
  );
};

export default Navbar;