import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navigation.css';
import smoothscroll from 'smoothscroll-polyfill'; // Import the smoothscroll polyfill
import { handleNavigationClick } from '../../functions.js';
import Navbar from '../../Navbar';
import FooterSection from '../../FooterSection';
import AboutSection from '../About/AboutSection.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import the arrow-down icon
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

smoothscroll.polyfill();

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  React.useEffect(() => {
    handleNavigationClick(0); // Pass the desired scroll amount in pixels
    // Trigger the fade-in effect after the component mounts
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Slight delay to ensure the component has mounted
  }, []);


  const scrollToAboutSection = () => {
    const aboutSection = document.querySelector('.about-section');
    if (aboutSection) {
      aboutSection.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };



  return (
    <div className="container">
      <div className="background-image">
        <div className="phrases-wrap">
          <div className="mainLogo">
            {/* <img className="mainLogoImage" src={lukatLogo} alt="lukat" /> */}
          </div>

        </div>

      </div>
      <div className="about-section-wrap">
        <div className="about-section">
          {/* <AboutSection /> */}
          <FooterSection />
        </div>
      </div>


    </div>

  );
};

export default Navigation;