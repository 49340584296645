
import React, { useEffect, useState } from 'react';
import menuImage from './menuImage.png';
import './MenuSection.css'; // Make sure to import the CSS file
import { Link } from 'react-router-dom';
import Navbar from './Navbar';


const MenuSection = () => {

  return (
    <div className="menu-section">

      {/* <div class="menu-images-carroussel">
      </div>
      <div class="menu-description">

        <div class="menu-description-title">
          <span class="title">
            MENU
          </span>
        </div>
        <div class="menu-description-text">
          <span class="text">
            Inspirado no melhor das nossas regiões, reunimos os melhores curadores, com produtos locais e que respeitam a sazonalidade. A proposta é um salmo aos sabores tradicionais e à inovação dos curadores que nos merecem total confiança e apoio.
          </span>
        </div>
        <div class="menu-buttons">
          <div class="food-menu">
            <button
              class="button-food-menu"
              onClick={() => window.open('/menu2024.pdf', '_blank')}
            >
              MENU
            </button>
          </div>
          <div class="drinks-menu">
            <button 
            class="button-drinks-menu" 
            onClick={() => window.open('/menubar2024.pdf', '_blank')}>
              BAR
            </button>
          </div>
        </div>

      </div> */}
    </div>
  )
};

export default MenuSection;
