import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Navigation from './MainPage/Main/Navigation';
import MenuSection from './MenuSection';
import FooterSection from './FooterSection';
import AboutSection from './MainPage/About/AboutSection';
import './App.css';
import CookieConsent from 'react-cookie-consent'

function App() {
  useEffect(() => {
    // Add the fade-in class to the body once the component is mounted
    document.body.classList.add('fade-in');
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Apply different styles based on windowWidth
  const cookieStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    color: '#000',
    width: windowWidth <= 476 ? '300px' : windowWidth <= 765 ? '80%' : '400px', // Adjust width based on screen size
    minHeight: '200px',
    padding: windowWidth <= 476 ? '10px 20px' : '20px 40px', // Adjust padding
    borderRadius: '2px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    zIndex: 1001,
    background: '#F9f7DC'
  };

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Navigation />} />
          <Route path="/catering-eventos/menu" element={<MenuSection />} />
        </Routes>
        <CookieConsent
          location="none" // Disable default positioning to use custom centering
          style={cookieStyle}
          overlay={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            backdropFilter: 'blur(5px)',
            position: 'fixed',  // Make overlay fixed to cover full screen
            top: 0,
            left: 0,
            width: '100vw',
            height: '100%',
            zIndex: 1000
          }}
          buttonStyle={{
            background: '#746861',
            color: '#fff',
            fontSize: '14px',
            borderRadius: '2px',
            padding: '10px 20px',
            margin: '10px',
            border: 'none',
            cursor: 'pointer'
          }}
          declineButtonStyle={{
            background: '#fff',
            color: '#746861',
            borderRadius: '2px',
            border: '1px solid #746861',
            padding: '10px 20px',
            margin: '10px',
            cursor: 'pointer'
          }}
          buttonText="Aceitar todos"
          declineButtonText="Rejeitar todos"
          enableDeclineButton
          expires={30}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            flex: 'none',  // Override any flex properties
            margin: '10px',  // Adjust margin as needed
          }}>
            <p style={{ marginBottom: '10px', textAlign: 'center' }}>
            Utilizamos cookies próprios e de terceiros para compreender a utilização da nossa loja online e poder melhorá-la, 
            adaptar o conteúdo às suas preferências e personalizar os nossos anúncios, marketing e publicações nas redes sociais. 
            Pode aceitar todos ou rejeitá-los.
            Lembre-se de que rejeitar cookies pode afetar a sua experiência de compra. 
            Para mais informações, consulte a nossa
            </p>
            <a href="/politicadecookies.pdf"
              style={{ textDecoration: 'underline', color: '#000' }}
              target="_blank"   // Opens PDF in a new tab
              rel="noopener noreferrer"  // For security reasons
            > 
              Política de Cookies
            </a>
          </div>
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
