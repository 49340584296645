// src/LocationsSection.js
import React from 'react';
import './FooterSection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const FooterSection = () => {
  return (
    <footer className="footer">
      <div className="footer-bottom">
        <div className="footer-first">
          Contactos
        </div>
        <div className="footer-address">
          <a
            href="https://www.google.com/maps/search/?api=1&query=Rua+Aníbal+Cunha,+8,+4050-609+Porto"
            target="_blank"
            rel="noopener noreferrer"
            className="address"
          >
            Rua da Torrinha, 178 <br />
            4050-609, Porto
          </a>
        </div>
        <div className="footer-telephone">
          <span className="telephone-disclaimer">
            Telemóvel
          </span>
          <a href="tel:+351917065744">+351 917 065 744 (Chamada para rede móvel nacional)</a>
        </div>
        <div className="footer-email">
          <span className="email-disclaimer">
            Email
          </span>
          <a href="mailto:info@lukat.pt">info@lukat.pt</a>
        </div>

        <div className="footer-whatsapp">
          <a
            href="https://wa.me/351917065744?text=Hello%20lu'kat%2C%20I%20would%20like%20to%20get%20in%20touch!"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-button"
          >
            <button>
              <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon"/>
            </button>
          </a>
        </div>

        {/* <div className="footer-copyright">
          <p>Criado por GR.</p>
        </div> */}
      </div>
    </footer>
  );
};



export default FooterSection;

